export const axiosMethodKeys = [
    'request',
    'get',
    'delete',
    'head',
    'options',
    'post',
    'put',
    'patch',
];
export const httpMethodKeys = ['requests', ...axiosMethodKeys];
export const httpDefaultConfig = {
    formatData(res) {
        var _a, _b;
        return (_b = (_a = res.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : res.data;
    },
    formatError(err) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return ((_k = (_g = (_d = (_c = (_b = (_a = err.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : (_f = (_e = err.response) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.message) !== null && _g !== void 0 ? _g : (_j = (_h = err.response) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.error) !== null && _k !== void 0 ? _k : err.message);
    },
};
export const wsDefaultConfig = {
    checkData(res) {
        return Boolean(res);
    },
    formatData(res) {
        return res;
    },
    formatError(err) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return ((_k = (_g = (_d = (_c = (_b = (_a = err.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : (_f = (_e = err.response) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.message) !== null && _g !== void 0 ? _g : (_j = (_h = err.response) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.error) !== null && _k !== void 0 ? _k : err.message);
    },
};
