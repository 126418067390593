import useApi from "./core/useApi.mjs";
import useApiOnce from "./core/useApiOnce.mjs";
import { getPramsAndOnLoad } from "../utils.mjs";
import useSuspenseApi from "./core/useSuspenseApi.mjs";
export default (rootMethods) => {
    return {
        useApi({ suspense = false, } = {}) {
            return useApi(rootMethods, { suspense });
        },
        useApiOnce(...args) {
            const [params, onLoad] = getPramsAndOnLoad(args);
            return useApiOnce(rootMethods, params, onLoad);
        },
        createSuspense({ cache = false } = {}) {
            const store = { cache };
            return function (...args) {
                const [params, onLoad] = getPramsAndOnLoad(args);
                return useSuspenseApi(rootMethods.requests, store, params, onLoad);
            };
        },
    };
};
