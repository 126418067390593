var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback, useMemo, useRef, useState } from 'react';
const initialStatus = { loading: false, prev: { loading: false } };
export default function useStatus(startAsLoading = false) {
    const [status, setStatus] = useState(() => (Object.assign(Object.assign({}, initialStatus), { loading: startAsLoading })));
    const statusRef = useRef(status);
    statusRef.current = status;
    const setState = useCallback((value) => {
        const _a = statusRef.current, { prev: _ } = _a, newRest = __rest(_a, ["prev"]);
        const _b = Object.assign(Object.assign({}, initialStatus), value), { prev: __ } = _b, oldRest = __rest(_b, ["prev"]);
        if (JSON.stringify(oldRest) === JSON.stringify(newRest))
            return;
        setStatus((prev) => (Object.assign(Object.assign(Object.assign({}, initialStatus), value), { prev })));
    }, []);
    return useMemo(() => (Object.assign(Object.assign({}, status), { setState })), [status]);
}
