var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export default (methods, setStatus) => {
    const result = {};
    for (let key in methods) {
        const axiosWrappedFn = (...args) => __awaiter(void 0, void 0, void 0, function* () {
            setStatus({ loading: true });
            const axiosFn = methods[key];
            const res = yield axiosFn(...args);
            res instanceof Array
                ? setStatus({ responses: res, ok: res.every((r) => r.ok) })
                : setStatus({ response: res, ok: res.ok });
            return res;
        });
        result[key] = axiosWrappedFn;
    }
    return result;
};
