import { useMemo } from 'react';
import useStatus from "../../hooks/useStatus.mjs";
import useSuspense from "../../hooks/useSuspense.mjs";
import useMemoOnce from "../../hooks/useMemoOnce.mjs";
import createStatusMethods from "../createStatusMethods.mjs";
export default (base, { startLoading = false, suspense = false }) => {
    const state = useStatus(startLoading);
    useSuspense(suspense && state.loading);
    const methods = useMemoOnce(() => createStatusMethods(base, state.setState));
    return useMemo(() => (Object.assign(Object.assign(Object.assign({}, state), methods), { state, methods })), [state]);
};
