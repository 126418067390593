import { useEffect, useRef } from 'react';
export default (fetch, store, params, onLoad) => {
    const data = useRef(store.response);
    useEffect(() => {
        if (store.cache)
            return;
        delete store.response;
        return () => {
            delete store.response;
        };
    }, []);
    if (data.current !== undefined)
        return data.current;
    if (store.promise)
        throw store.promise;
    store.promise = new Promise((resolve) => {
        fetch(...params)
            .then((res) => {
            store.response = res;
            onLoad && onLoad(res);
        })
            .finally(() => {
            delete store.promise;
            resolve();
        });
    });
    throw store.promise;
};
