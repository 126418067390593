import { useRef } from 'react';
export default function (suspended = false) {
    const promise = useRef();
    if (suspended && !promise.current) {
        throw new Promise((resolve) => {
            promise.current = { resolve };
        });
    }
    if (!suspended && promise.current) {
        promise.current.resolve();
        promise.current = undefined;
    }
}
