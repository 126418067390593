var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import useApi from "./useApi.mjs";
import { useCallback, useMemo } from 'react';
import useEffectOnce from "../../hooks/useEffectOnce.mjs";
export default (instance, params, onLoad) => {
    const api = useApi(instance, {
        startLoading: true,
    });
    const retryFunction = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield api.requests(...params);
        onLoad && onLoad(res);
    }), []);
    useEffectOnce(retryFunction);
    return useMemo(() => (Object.assign(Object.assign({}, api.state), { state: api.state, retry: retryFunction })), [api.state]);
};
