var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import axios from 'axios';
import createHooks from "./createHooks.mjs";
import { httpDefaultConfig } from "../config.mjs";
import createBaseMethods from "./createMethods.mjs";
export default function (options) {
    const _a = Object.assign(Object.assign({}, httpDefaultConfig), (options !== null && options !== void 0 ? options : {})), { formatData, formatError } = _a, axiosOptions = __rest(_a, ["formatData", "formatError"]);
    const axiosInstance = axios.create(axiosOptions);
    const baseConfig = { formatData, formatError };
    const baseMethods = createBaseMethods(axiosInstance, baseConfig);
    const hooks = createHooks(baseMethods);
    return {
        hooks,
        axios: axiosInstance,
        methods: baseMethods,
    };
}
