import { configureStore } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { generateReducers, generateActions } from "./utils.mjs";
export default (...slices) => {
    const reducer = generateReducers(slices);
    const store = configureStore({ reducer });
    const actions = generateActions(store.dispatch, slices);
    function useStore(selector) {
        return useSelector(selector);
    }
    return [store, useStore, actions];
};
