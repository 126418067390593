export function generateReducers(slices) {
    const reducers = {};
    slices.forEach((slice) => {
        reducers[slice.name] = slice.reducer;
    });
    return reducers;
}
export function generateActions(dispatch, slices) {
    function wrapActions(actions) {
        const newActions = {};
        for (let key in actions) {
            newActions[key] = (...args) => {
                return dispatch(actions[key](...args));
            };
        }
        return newActions;
    }
    const actions = {};
    slices.forEach((slice) => {
        actions[slice.name] = wrapActions(slice.actions);
    });
    return actions;
}
