import { useEffect, useRef } from 'react';
export default function () {
    const result = useRef();
    if (!result.current) {
        let prevController;
        const getIsActive = () => {
            return Boolean(prevController &&
                prevController.signal &&
                prevController.signal.aborted === false);
        };
        const abortSignal = () => {
            const isActive = getIsActive();
            if (isActive)
                prevController === null || prevController === void 0 ? void 0 : prevController.abort();
            prevController = null;
            return isActive;
        };
        const generateSignal = () => {
            abortSignal();
            const controller = new AbortController();
            prevController = controller;
            return controller.signal;
        };
        result.current = [generateSignal, abortSignal, getIsActive];
    }
    useEffect(() => result.current[1], []);
    return result.current;
}
