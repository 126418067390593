import { createSlice, } from '@reduxjs/toolkit';
export default function (name, config) {
    const usable = {};
    for (let key in config.reducers) {
        usable[key] = (state, { payload }) => {
            return config.reducers[key](state, payload);
        };
    }
    const reducers = usable;
    return createSlice(Object.assign(Object.assign({}, config), { reducers,
        name }));
}
